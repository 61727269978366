<template>
  <r-e-dialog
      title="注意"
      :center="false"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="400px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
<div style="display: flex;justify-content: center">
  <i class="el-icon-warning" style="color: #FF7C33;margin-right: 25px;font-size: 28px"></i><span style="width: 200px;line-height: 30px;text-align: center">{{ itemtitle }}？</span>
</div>
  </r-e-dialog>
</template>

<script>
import {validateNumber} from "@/views/bill-management/payment-record/data";
import REDialog from "@/components/Gloabl/r-e-dialog/index.vue";
import {Invoicereviewbtn} from "@/api/community-management";

export default {
  name: "dialog-add-invoice",
  components: {REDialog},
  props:{
    itemtitle:{
      type:String,
      default:'确认通过申请吗'
    },
    status:{
      type:String,
      default:'1'
    },
    uuid:{
      type:String,
      default:'1'
    }
  },
  data() {
    return {
      addDialogVisible: false,

    }
  },
  methods:{
    // 打开弹框
    openinvoiceclick(){
      // this.rowuuid = uuid
      this.addDialogVisible = true
    },
    // 确认按钮
    clickSubmit(){
      const loading = this.$loading({lock: true, text: "加载中...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     // alert('submit!');
      //     console.log(this.formdata)

      Invoicereviewbtn(this.uuid,{status:this.status}).then(res => {
        console.log(res)
        this.addDialogVisible = false
        this.$emit('btnfanhui',)
        loading.close()
        this.$message({
          type: 'success',
          message: '修改成功!'
        });
        // this.$parent.btnfanhui()
      })
      //
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
    //   this.formdata =  {
    //     productName:'',//商品名称
    //     commodityCode:'',//商品编码
    //     rent:'',//房租
    //     electricCharge:'',//电费
    //     tapWater:'',//自来水
    //     sewage:'',//污水
    //     serviceCharge:'',//服务费
    //   }
    },

  }
}
</script>

<style scoped lang="scss">

</style>
