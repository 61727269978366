import {timeFormat} from "@custom";
import {regFenToYuan} from "@/utils/hooks";

export function tableColumn(_this){
    return  [

        {
            type: 'selection',
            width: 70,
            selectable:(row) => {
                // console.log(row)
                return !row.tuiFang;
            },
        },
        {
            // prop: "name",
            label: "姓名",
            width: 200,
            render: (h, {row}) => {
                return row.leasor.name
            }
        },
        {
            // prop: "userName",
            label: "手机号",
            width: 200,
            render: (h, {row}) => {
                return row.leasor.tel1
            }
            // render: (h, {row}) => {
            //     const {apartmentName} = row;
            //     return h("el-link", {
            //         props: {type: "primary", underline: false},
            //         on: {
            //             click() {
            //                 _this.$router.push({
            //                     name: "house-resource",
            //                     query: {data: {apartmerntName: apartmentName}}
            //                 });
            //             }
            //         }
            //     }, apartmentName);}
        },
        {
            label: "开票类型",
            width: 100,
            render: (h, {row}) => {
                return row.typeName
            }
        },
        // 2024/02/22 张晓瑜修改抬头类型展示 titleType 0:暂无 1:个人 2:单位
        {
            prop: "titleType",
            label: "抬头类型",
            width: 150,
            render: (h, {row:{titleType}}) => {
                switch (titleType) {
                    case 0:
                        titleType = '暂无';
                        break;
                    case 1:
                        titleType = '个人';
                        break;
                    case 2:
                        titleType = '单位';
                        break;
                }
                return h("span", titleType);
            }
        },
        {
            label: "发票抬头",
            width: 150,
            render: (h, {row}) => {
                if(row.invoiceTitle){
                    return row.invoiceTitle.invoiceRise
                }else {
                    return '暂无'
                }

            }
        },
        {
            // prop: "dutyNumber",
            label: "税号",
            width: 150,
            render: (h, {row}) => {
                // if(row.dutyNumber){
                //     return row.dutyNumber
                // }else {
                //     return '暂无'
                // }
                if(row.invoiceTitle)  return row.invoiceTitle.taxNumber
                return '暂无'
            }
        },
        {
            // prop: "dutyNumber",
            label: "邮箱",
            width: 150,
            render: (h, {row}) => {
                // if(row.dutyNumber){
                //     return row.dutyNumber
                // }else {
                //     return '暂无'
                // }
                return row.leasor.mailAddress
            }
        },
        {
            prop: "billNum",
            label: "账单号",
            width: 200,
        },
        {
            label: "房源地址",
            width: 250,
            render: (h, {row}) => {
                return row.apartment.name
            }
        },
        {
            prop: "payBill.typeName",
            label: "账单类型",
            width: 150,
        },
        {
            prop: "paymentAmount",
            label: "开票金额（元）",
            width: 150,
            render: (h, {row}) => {
                const newpaymentAmount =  regFenToYuan(row.paymentAmount)
                return newpaymentAmount
            }
        },
        {
            prop: "billingDate",
            label: "申请时间",
            width: 150,
            render: (h, {row}) => {
                // return row.typeName
                return timeFormat(new Date(row.billingDate), "yyyy-MM-dd HH:mm:ss");
            }

        },
        {
            prop: "status",
            label: "状态",
            width: 150,
        },


    ];
}