<template>
  <r-e-dialog
      title="注意"
      :center="false"
      class="layer-contract-detail"
      :visible.sync="addDialogVisible"
      show-footer
      top="10vh"
      width="400px"
      @click-submit="clickSubmit"
      @click-cancel="clickCancelerr"
      @before-close="clickCancel"
      :footerBtnName="footerBtnName"
  >
<div style="display: flex;justify-content: center">
  <i class="el-icon-warning" style="color: #FF7C33;margin-right: 25px;font-size: 28px"></i><span style="width: 200px;line-height: 30px;text-align: center">{{ itemtitle }}？</span>
</div>
  </r-e-dialog>
</template>

<script>
import {validateNumber} from "@/views/bill-management/payment-record/data";
import REDialog from "@/components/Gloabl/r-e-dialog/index.vue";
import {Invoicereviewbtn} from "@/api/community-management";

export default {
  name: "dialog-add-invoice",
  components: {REDialog},
  props:{
    itemtitle:{
      type:String,
      default:'确认通过申请吗'
    },
    rowlist:{
      type:Array
    }
  },
  data() {
    return {
      footerBtnName:['拒绝', '通过'],
      addDialogVisible: false,
    }
  },
  methods:{
    // 打开弹框
    openinvoiceclick(){
      this.addDialogVisible = true
    },
    // 确认按钮
    clickSubmit(){
      const loading = this.$loading({lock: true, text: "加载中...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
      // status 1通过 2不通过
      this.rowlist.forEach(item => {
          Invoicereviewbtn(item,{status:1}).then(res => {
            console.log(res)
          })
      })
      loading.close()
      this.$message({
        type: 'success',
        message: '批量审批成功!'
      });
          this.addDialogVisible = false
          this.$emit('shuaxin')
    },
    // 关闭弹框
    clickCancel(){
      this.addDialogVisible = false
    },
    // 拒绝
    clickCancelerr(){
      this.rowlist.forEach(item => {
        Invoicereviewbtn(item,{status:2}).then(res => {
          console.log(res)
          this.$message({
            type: 'success',
            message: '批量审批成功!'
          });
        })
      })
      this.addDialogVisible = false
      this.$emit('shuaxin')
    },
  }
}
</script>

<style scoped lang="scss">

</style>
