import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 100
    },
    {
        prop: "amount",
        label: "金额(元)",
        width: 100,
        render: (h, {row}) => {
            return h("span", !row.amount ? "" : numberFormat(row.amount / 100));
        },
    },
    {
        prop: "billingDate",
        label: "账单日期",
        width: 150,
        render: (h, {row}) => {
            return h("span", !row.billingDate ? "" : timeFormat(new Date(Number(row.billingDate)), 'yyyy-MM-dd HH:mm:ss'));
        },
        sortable: true
    },
    {
        prop: "startDate",
        label: "开始时间",
        width: 150,
        render: (h, {row}) => {
            return h("span", !row.startDate ? "" : timeFormat(new Date(Number(row.startDate)), 'yyyy-MM-dd'));
        },
        sortable: true
    },
    {
        prop: "endDate",
        label: "结束时间",
        width: 150,
        render: (h, {row}) => {
            return h("span", !row.endDate ? "" : timeFormat(new Date(Number(row.endDate)), 'yyyy-MM-dd'));
        },
        sortable: true
    },
    {
        prop: "address",
        label: "地址",
        width: 150,
    },
    {
        prop: "leasorName",
        label: "租户名称",
        width: 100
    },
    {
        prop: "dateRange",
        label: "账单时间段",
        width: 150,
    },
    {
        prop: "paymentChannel",
        label: "支付渠道",
        width: 100
    },
    {
        prop: "paid",
        label: "支付状态",
        width: 100,
        render: (h, {row}) => {
            return h("span", row.paid ? "已支付" : "未支付");
        },
    },/*
    {
        prop: "invoiced",
        label: "发票",
        width: 100,
        render: (h, { row }) => {
            return h("span",row.invoiced?"已开发票":"未开发票");
        },
    },*/
    {
        prop: "comment",
        label: "备注",
    },
];

export const validateNumber1 =  (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字
    if (!/^\d+$/.test(value)) {
        callback(new Error('请输入数字'));
        return false;
    }

    // 转换成数字类型
    value  = parseInt(value );
    // 校验是否在一百以内
    if (value  >= 0 && value  <= 100) {
        return true;
    } else {
        callback(new Error('税率请保持在0-100之间'));
        return false;
    }
}
export const validateNumber2 =  (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字
    if (!/^\d+$/.test(value)) {
        callback(new Error('请输入数字'));
        return false;
    }

    // 转换成数字类型
    value  = parseInt(value );
    // 校验是否在一百以内
    if (value  >= 0 && value  <= 100) {
        return true;
    } else {
        callback(new Error('税率请保持在0-100之间'));
        return false;
    }
}
export const validateNumber3 =  (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字
    if (!/^\d+$/.test(value)) {
        callback(new Error('请输入数字'));
        return false;
    }

    // 转换成数字类型
    value  = parseInt(value );
    // 校验是否在一百以内
    if (value  >= 0 && value  <= 100) {
        return true;
    } else {
        callback(new Error('税率请保持在0-100之间'));
        return false;
    }
}
export const validateNumber4 =  (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字
    if (!/^\d+$/.test(value)) {
        callback(new Error('请输入数字'));
        return false;
    }

    // 转换成数字类型
    value  = parseInt(value );
    // 校验是否在一百以内
    if (value  >= 0 && value  <= 100) {
        return true;
    } else {
        callback(new Error('税率请保持在0-100之间'));
        return false;
    }
}
export const validateNumber5 =  (rule, value, callback) => {
    // 使用正则表达式检查输入是否为数字
    if (!/^\d+$/.test(value)) {
        callback(new Error('请输入数字'));
        return false;
    }

    // 转换成数字类型
    value  = parseInt(value );
    // 校验是否在一百以内
    if (value  >= 0 && value  <= 100) {
        return true;
    } else {
        callback(new Error('税率请保持在0-100之间'));
        return false;
    }
}